import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("open_drawer")) {
      if (this.hasButtonTarget) {
        setTimeout(() => {
          this.openDrawer();
        }, 50);
      }
    }
  }

  openDrawer() {
    this.buttonTarget.click();
  }

  closeAndOpenDrawers() {
    const closeExportDrawerButton = document.querySelector("[data-drawer-hide='providers-export-drawer']");
    const openFilterDrawerButton = document.querySelector("[data-drawer-target='providers-filter-drawer']");

    if (closeExportDrawerButton) {
      closeExportDrawerButton.click();
    }

    setTimeout(() => {
      if (openFilterDrawerButton) {
        openFilterDrawerButton.click();
      }
    }, 300);
  }
}
