import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav-highlight"
export default class extends Controller {
  static targets = ["link", "parentItem"];
  static values = {
    childActiveClass: { type: String, default: "bg-onyx-50" },
    parentActiveClass: { type: String, default: "bg-onyx-100" },
  };

  connect() {
    document.addEventListener("turbo:load", () => this.highlightCurrentLink());
  }

  highlightCurrentLink() {
    this.linkTargets.forEach((link) => {
      link.classList.remove(this.childActiveClassValue);
    });

    this.parentItemTargets.forEach((parent) => {
      parent.classList.remove(this.parentActiveClassValue);
    });

    const currentPath = globalThis.location.pathname;

    const currentLink = this.linkTargets.find((link) => {
      const linkPath = link.getAttribute("href");

      return currentPath === linkPath || (linkPath !== "/" && currentPath.startsWith(linkPath));
    });

    if (!currentLink) return;

    currentLink.classList.add(this.childActiveClassValue);

    const parentLink = currentLink
      ?.closest("ul")
      ?.closest("li")
      ?.querySelector(":scope > a[data-nav-highlight-target='parentItem']");

    if (parentLink) {
      parentLink.classList.add(this.parentActiveClassValue);
    }
  }
}
